import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Lottie from 'react-lottie'
import * as loadingAnimation from './59284-loading-screen.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation.default,
    renderSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundRepeat: 'no repeat',
        backgroundPosition: 'center center',
    },
    spinner: {
        display: 'block',
        margin: '0 auto',
    },
}));

const Loading = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <>
                <Lottie options={defaultOptions} isClickToPauseDisabled={true} height={400} width={400} />
            </>
        </div>
    )
}

export default Loading