import React, { useContext } from 'react'
import NewCompiler from "../views/newCompiler/NewCompiler";
import Finish from "../views/finish/Finish";
import Login from "../views/login/Login";
import { userContext } from '../context/AuthContextProvider'
import AuthProvider from '../context/AuthContextProvider'
import { BrowserRouter as Router, HashRouter, Route, Redirect } from 'react-router-dom'

function PrivateRoute({ children, ...rest }) {
    const context = useContext(userContext)
    return (
        <Route
            {...rest}
            render={({ location }) =>
                localStorage.getItem('auth') ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export const CompilerRoutes = () => {
    // const Auth = React.useContext(AplicantContext);
    return (
        <Router>
            <AuthProvider>
                <Route exact path="/" component={Login} />
                <Route exact path="/finish" component={Finish} />
                <PrivateRoute exac path="/test">
                    <NewCompiler />
                </PrivateRoute>
            </AuthProvider>
        </Router>
    )
}

export default CompilerRoutes;