import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    FormGroup,
    TextField,
    Grid,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Axios from 'axios'
import Swal from 'sweetalert2';
import bgImage from '../../assets/images/banner1.jpg'
import Loading from '../loading/Loading'

const useStyles = makeStyles({
    rootLogin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: '#f3f5f5',
        backgroundImage: `url(${bgImage})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    containerForm: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f3f5f5',
        padding: '15px',
        borderRadius: '25px',
    },
    formCenter: {
        minWidth: '200px',
        textAlign: 'center',
        padding: '10px'
    },
    formGroup: {
        margin: '20px 0px',
    },
    buttonSubmit: {
        color: "#fff",
        textTransform: "none",
        fontWeight: '600',
        backgroundColor: '#2f50ad',
        border: '1px solid #F4991E',
        borderRadius: '50px',
        padding: '5px 20%',
        margin: '5px 0px',
        "&:hover": {
            backgroundColor: '#2f50ad',
            color: '#fff',
        }
    },
});

const Login = () => {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(null)
    const [loading, setLoading] = useState(false)
    const [dataUser, setDataUser] = useState({
        email: '',
        code: ''
    })

    const submitHandler = () => {
        setLoading(true)
        // Axios.post('http://coding.id/api/assessment/verifikasicode', dataUser)
        Axios.post('http://127.0.0.1:8000/api/assessment/verifikasicode', dataUser)
            .then((res) => {
                localStorage.setItem('auth', true)
                setRedirect({ pathname: '/test', state: { dataAssessment: res.data.assessment, listQuestion: res.data.assessment.list_question, dataMember: res.data.member, timer: res.data.timer } })
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                // console.log(err.response.data.message);
                if (err.response.data.message == 'Times up') {
                    Swal.fire({
                        text: "Times Up !",
                        icon: 'error',
                        showCloseButton: true
                    })
                } else if (err.response.data.message == 'Wrong Assessment Code') {
                    Swal.fire({
                        text: "Invalid Assessment Code",
                        icon: 'error',
                        showCloseButton: true
                    })
                } else if (err.response.data.message == 'Email Not Found') {
                    Swal.fire({
                        text: "Invalid Email",
                        icon: 'error',
                        showCloseButton: true
                    })
                } else {
                    Swal.fire({
                        text: "Email or Assessment Code Not Verified",
                        icon: 'error',
                        showCloseButton: true
                    })
                }
            })
    }

    const changeHandler = (event) => {
        let nama = event.target.name
        let value = event.target.value
        setDataUser({
            ...dataUser,
            [nama]: value
        })
    }

    const renderRedirect = () => {
        if (redirect) {
            return <Redirect to={redirect} />
        }
    }

    if (loading) {
        return <Loading />
    } else {
        return (
            <>
                {renderRedirect()}
                <div className={classes.rootLogin}>
                    <div className={classes.containerForm}>
                        <div className={classes.formCenter}>
                            <h4 style={{ margin: '15px 0px' }}>Verification Code</h4>
                            <p>Please input your Email and Assessment Code to take this assessment</p>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <FormGroup className={classes.formGroup}>
                                        <TextField
                                            id="outlined-basic"
                                            label="email"
                                            size="small"
                                            variant="outlined"
                                            name="email"
                                            value={dataUser.email}
                                            onChange={changeHandler}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup className={classes.formGroup}>
                                        <TextField
                                            id="outlined-basic"
                                            label="code"
                                            size="small"
                                            variant="outlined"
                                            name="code"
                                            value={dataUser.code}
                                            onChange={changeHandler}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Button
                                className={classes.buttonSubmit}
                                onClick={submitHandler}
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Login