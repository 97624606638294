import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
} from '@material-ui/core'
import { Redirect, useLocation } from 'react-router-dom';
import successIcon from '../../assets/icons/Success.svg'

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: 'white',
        padding: '20px',
        height: '100vh',
    },
    inlineCenter: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '10px 0px',
    },
    buttonNext: {
        background: '#F4991E',
        border: '1px solid #F4991E',
        color: 'white',
        textTransform: 'none',
        marginLeft: '10px',
        padding: '5px 30px',
        '&:hover': {
            background: "#F4991E",
        },
    },
    containerResend: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "90%",
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "white"
    },
    containerResendText: {
        marginBottom: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    resendTitle: {
        fontWeight: "700",
        margin: "10px 0"
    },
    resendText: {
        textAlign: 'center',
        fontSize: "14px",
        fontWeight: "400",
        color: "#828282"
    },
}));


const Finish = () => {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(null)
    const [loading, setLoading] = useState(false);

    const backHandler = () => {
        setRedirect({ pathname: '/' })
    }

    const renderRedirect = () => {
        if (redirect) {
            return <Redirect to={redirect} />
        }
    }

    return (
        <>
            {renderRedirect()}
            <div className={classes.content}>
                <div className={classes.containerResend}>
                    <img src={successIcon} alt='Loading' style={{ height: "300px", width: "300px" }} /> <br />
                    <h2 className={classes.resendTitle}>Congratulations!</h2>
                    <div className={classes.containerResendText}>
                        <p className={classes.resendText} style={{ width: '60%' }}>Congratulations, You’ve submitted assessment</p>
                        <Button variant="contained" onClick={backHandler} size="small" className={classes.buttonNext}>Back to Home</Button>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Finish
